const dev = {
  baseUrl: "https://localhost:5001/api/",
};
const stg = {
  baseUrl: "https://insure-app.com/api/",
};

const config = (env: any) => {
  switch (process.env.REACT_APP_STAGE) {
    case "dev":
      return dev;
    case "stg":
      return stg;
  }
};

export default {
  // Add common config values here
  baseUrl: "/api/",
  ReCAPTCHA_Key: "6Lf3DQcTAAAAAK4MvuOq7_kR7-hM97VwnFw3Sx2f",
  EncryptionKey: "PxL+0gL4fa9allhfSvGfxdyXNn3ISqqT6aMstQMNxvM=",
  images: {
    LogoPath: "/logo.png",
    LogoNegativePath: "/logo.png",
    FaviconPath: "/favicon.ico",
  },
  ...config(process.env.REACT_APP_STAGE),
};
